import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { callApiAction } from "../../../store/actions/commonAction";
import { closeModal, openModal } from "../../../store/actions/modalAction";
import DailyUpdateUi from "./DailyUpdateUi";
import { SNACK_BAR_VARIETNS } from "../../../utils/constants";
import {
  fetchUserWiseDepartment,
  getAllDailyUpdates,
} from "../../../apis/dailyupdate.api";
import { EmployeeTaskUserContext } from "../EmployeeMain";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import DailyUpdateEditor from "./DailyUpdateEditor";
import DailyUpdateReportController from "./DailyUpdateReportController";

const DailyupdateController = (userId) => {
  const dispatch = useDispatch();
  const selectedUser = useContext(EmployeeTaskUserContext);
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [error, setError] = useState("");
  const [submittedTasks, setSubmittedTasks] = useState({});

  const [filters, setFilters] = useState({
    err: "",
    pageNo: 1,
    pageSize: 25,
    date: moment(),
    data: true,
  });

  const fetchDepartments = async () => {
    setLoading(true);
    try {
      const result = await fetchUserWiseDepartment(); 
      if (
        result &&
        result.status === 1 &&
        Array.isArray(result.data) &&
        result.data.length > 0
      ) {
        const departmentsData = result.data[0].secondary_department;

        const secondaryDepartments = departmentsData
          .map((item) => {
            if (item && item.department) {
              return {
                _id: item.department._id,
                name: item.department.name,
                parent_id: item.parent_id || null, 
              };
            } else {
              return null;
            }
          })
          .filter((dept) => dept !== null); 

        
        const uniqueDepartments = secondaryDepartments.reduce(
          (acc, current) => {
            const x = acc.find((item) => item._id === current._id);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          },
          []
        );

        setDepartments(uniqueDepartments); // Update state with unique department data
      } else {
        setDepartments([]); // No departments available
      }
    } catch (error) {
      setError("Error fetching departments");
      setDepartments([]);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchTasks = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await getAllDailyUpdates({
            ...filters,
            date: moment(filters.date).format("YYYY-MM-DD"), 
            userId,
          }),
        async (response) => {
          setLoading(false);

          const finalList = {};

          if (response && Array.isArray(response)) {
            response.forEach((item) => {
              const departmentName = item.department;
              const taskDate = moment(item.date).format("YYYY-MM-DD"); 

              // Compare only the date part
              if (
                moment(taskDate).format("YYYY-MM-DD") ===
                moment(filters.date).format("YYYY-MM-DD")
              ) {
                if (!finalList[departmentName]) {
                  finalList[departmentName] = [];
                }
                finalList[departmentName] = finalList[departmentName].concat(
                  item.items
                );
              }
            });

            setSubmittedTasks(finalList);
          } else {
            setSubmittedTasks({});
          }
        },
        (err) => {
          setLoading(false);
          setSubmittedTasks({});
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
        }
      )
    );
  };

  useEffect(() => {
    fetchTasks();
  }, [filters.date, selectedUser]);

  const handleDateChange = (newDate) => {
    setFilters({ ...filters, date: newDate });
  };

  const openCKEditorModal = (departmentId) => {
    const department = departments.find((d) => d._id === departmentId);

    if (!department) {
      dispatch(callSnackBar("Department not found!", "error"));
      return;
    }

    dispatch(
      openModal({
        component: (
          <DailyUpdateEditor
            date={moment(filters.date).format("YYYY-MM-DD")} 
            departmentId={departmentId}
            secondaryParentId={department.parent_id}
            callBack={fetchTasks}
          />
        ),
        size: "md",
        title: `Daily Update of ${department.name || ""}`,
      })
    );
  };

  const openMonthlyReport = () => {
    dispatch(
      openModal({
        component: <DailyUpdateReportController />,
        size: "lg",
        title: "Monthly Daily Updates",
        confirmText: "Close",
        onCancle: () => dispatch(closeModal()),
      })
    );
  };

  return (
    <DailyUpdateUi
      loading={loading}
      date={filters.date}
      filters={filters}
      setDate={handleDateChange}
      departments={departments}
      submittedTasks={submittedTasks}
      maxDate={moment()}
      openMonthlyReport={openMonthlyReport}
      openCKEditorModal={openCKEditorModal}
    />
  );
};

export default React.memo(DailyupdateController);
