import React, { useEffect, useState } from "react";
import { createHoliday } from "../../apis/holiday.api";
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction";
import { fetchDepartments, createDepartment, updateDepartment } from "../../apis/department";
import UpdateDepartmentUI from "./UpdateDepartmentUI";
import { closeModal } from "../../store/actions/modalAction";
import { setDepartmentDataOnDeleteAction } from "../../store/actions/settingsAction";

const UpdateDepartmentController = ({ id, initialName, parents, callBack }) => {

    let intialParentID = ""
    let intialParentName = ""
    if (parents.length) {
        intialParentID = parents[0]._id
        intialParentName = parents[0].name
    }
    const dispatch = useDispatch()
    const updateDepartmentApi = updateDepartment
    const [loading, setLoading] = useState(false)
    const [parentId, setParentID] = useState(intialParentID);
    const [parentName, setParentName] = useState(intialParentName);
    const [name, setName] = useState(initialName)
    const [err, setErr] = useState("")
    const [string, setString] = useState("")
    const [departments, setDepartmens] = useState("")
    const fetchDepartmentsApi = fetchDepartments
    const {settings} = useSelector((state) => state)



    
    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchDepartmentsApi({ string }),
            (response) => {
                setDepartmens(response.result)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const UpdateDepartment = (e) => {
        e.preventDefault()
        setLoading(true)
        dispatch(callApiAction(
            async () => await updateDepartmentApi({ id,parentId: parentId, name }),
            (response) => {
                dispatch(closeModal())
                callBack({
                    id, name,
                })
                const updatedData = settings.department_data.map(item => (item._id == id ? {...item, name : name} : item))
                dispatch(setDepartmentDataOnDeleteAction(updatedData, settings.department_filters))
                setLoading(false)
            },
            (err) => {
                setLoading(false)
                setErr(err)
            }
        ))
    }
    useEffect(() => {
        fetchList()
    }, [string])
    return <UpdateDepartmentUI err={err} loading={loading} UpdateDepartment={UpdateDepartment} parentName={parentName} setParentName={setParentName} setName={setName} name={name} departments={departments} setString={setString} setParentID={setParentID} />
}

export default UpdateDepartmentController;