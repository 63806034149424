import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, IconButton } from '@mui/material';
import { getPmsById, getPmsPdf, getPmsReportingPerson, getPmsUpdate } from '../../apis/pms.api';
import { SNACK_BAR_VARIETNS } from '../../utils/constants';
import { callSnackBar } from '../../store/actions/snackbarAction';
import EmployeeTaskReviewUi from './EmployeeTaskReviewUi';

import { Download, Edit, Info } from '@mui/icons-material';

import { selectEmployee } from '../../store/actions/selectedEmployeeAction';
import { closeModal, openModal } from '../../store/actions/modalAction';
import PmsEditForm from './PmsEditForm';
import moment from 'moment';
import UserSearchBar from '../../components/inputs/SearchBar';
import { BasicProfileDetailsComponent } from '../profile/BasicProfileDetailsComponent';


const EmployeeTaskReviewController = () => {
  const dispatch = useDispatch();
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const { selectedEmployee } = useSelector((state) => state);
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    total: 0,
    search: '',
    date: moment(),
    employeeId: selectedEmployee?.user?._id || '',
    sort: 'total',
    sortDirection: -1,
  });

  useEffect(() => {
    fetchForms(
      filters.pageNo,
      filters.pageSize,
      filters.search,
      filters.date,
      filters.employeeId,
      filters.sort,
      filters.sortDirection
    );
  }, [
    filters.pageNo,
    filters.pageSize,
    filters.search,
    filters.date,
    filters.employeeId,
    filters.sort,
    filters.sortDirection,
  ]);

  const fetchForms = async (
    pageNo = 1,
    pageSize = 10,
    search = '',
    date,
    employeeId = '',
    sort = 'total'
  ) => {
    setLoading(true);
    const formattedDate = date.toISOString();
    try {
      const response = await getPmsReportingPerson({
        pageNo,
        pageSize,
        name: search,
        date: formattedDate,
        employeeId,
        sort,
      });
      if (response.status === 1 && response.data) {
        const groupedData = response.data.reduce((acc, item) => {
          const employeeId = item.user_id._id;
          const taskMonth = new Date(item.completion_date).getMonth() + 1;
          const taskYear = new Date(item.completion_date).getFullYear();
          const monthYearKey = `${taskMonth}-${taskYear}`;

          if (!acc[employeeId]) {
            acc[employeeId] = {};
          }
          if (!acc[employeeId][monthYearKey]) {
            acc[employeeId][monthYearKey] = {
              _id: employeeId,
              employee_name: `${item.user_id.first_name} ${item.user_id.last_name}`,
              review_month: monthYearKey,
              tasks: [],
            };
          }
          acc[employeeId][monthYearKey].tasks.push({
            _id: item._id,
            monthly_task: item.monthly_task,
            completion_date: item.completion_date,
            status: item.status || '',
            remarks: item.remarks || '',
          });
          return acc;
        }, {});

        let formList = Object.values(groupedData).flatMap((employeeForms) =>
          Object.values(employeeForms)
        );

        // Reverse the formList array to show the newest forms at the top
        formList = formList.reverse();

        const totalForms = response.totalCount || formList.length;

        setForms(formList);
        setFilters((prev) => ({
          ...prev,
          total: totalForms,
          pageNo,
          pageSize,
        }));
      } else {
        dispatch(callSnackBar('Failed to fetch forms', SNACK_BAR_VARIETNS.error));
      }
    } catch (error) {
      console.error('Error fetching forms:', error);
      dispatch(callSnackBar('Failed to fetch forms', SNACK_BAR_VARIETNS.error));
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = async (updatedForm) => {
    try {
      const updateData = {
        id: updatedForm._id,
        tasks: updatedForm.tasks.map((task) => ({
          _id: task._id,
          monthly_task: task.monthly_task, // Ensure this line is included
          completion_date: task.completion_date, // Ensure this line is included
          status: task.status ? parseInt(task.status) : '',
          remarks: task.remarks,
        })),
      };

      const response = await getPmsUpdate(updateData);
      if (response.status === 1) {
        dispatch(callSnackBar('Form updated successfully', SNACK_BAR_VARIETNS.suceess));

        setForms((prevForms) => {
          return prevForms.map((form) => {
            if (form._id === updatedForm._id && form.review_month === updatedForm.review_month) {
              return {
                ...form,
                tasks: form.tasks.map((task) => {
                  const updatedTask = updatedForm.tasks.find((ut) => ut._id === task._id);
                  return updatedTask ? { ...task, ...updatedTask } : task;
                }),
              };
            }
            return form;
          });
        });

        dispatch(closeModal());
      } else {
        dispatch(callSnackBar('Failed to update form', SNACK_BAR_VARIETNS.error));
      }
    } catch (error) {
      console.error('Error updating form:', error);
      dispatch(callSnackBar('Failed to update form', SNACK_BAR_VARIETNS.error));
    }
  };


  const handleEditClick = async (employeeId, reviewMonth) => {
    try {
      const [month, year] = reviewMonth.split('-');
      const response = await getPmsById({ id: employeeId });
      if (response.status === 1 && response.data) {
        const data = response.data.filter((task) => {
          const taskMonth = new Date(task.completion_date).getMonth() + 1;
          const taskYear = new Date(task.completion_date).getFullYear();
          return taskMonth === parseInt(month) && taskYear === parseInt(year);
        });
        if (data.length > 0) {
          const formattedForm = {
            _id: data[0].user_id._id,
            employee_name: `${data[0].user_id.first_name} ${data[0].user_id.last_name}`,
            review_month: reviewMonth,
            tasks: data.map((task) => ({
              _id: task._id,
              monthly_task: task.monthly_task,
              completion_date: task.completion_date,
              status: task.status || '',
              remarks: task.remarks || '',
            })),
          };
          dispatch(
            openModal({
              component: <PmsEditForm formData={formattedForm} onSubmit={handleFormSubmit} />,
              dialogProps: { maxWidth: 'lg' },
              size: 'lg',
              title: 'Employee Task Review',
              confirmText: 'Submit',
              resetText: 'Cancel',
              onCancel: () => dispatch(closeModal()),
              onConfirm: () => document.getElementById('form-submit-button').click(),
            })
          );
        } else {
          dispatch(callSnackBar('No tasks found for this employee', SNACK_BAR_VARIETNS.error));
        }
      } else {
        dispatch(callSnackBar('Failed to fetch form details', SNACK_BAR_VARIETNS.error));
      }
    } catch (error) {
      console.error('Error fetching form details:', error);
      dispatch(callSnackBar('Failed to fetch form details', SNACK_BAR_VARIETNS.error));
    }
  };

  const handleSearchChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: event.target.value,
      pageNo: 1, // Reset to page 1 on search
      employeeId: '', // Clear the selected employee when searching
    }));
  };

  const handleUserChange = (user) => {
    dispatch(selectEmployee(user));
    setFilters((prevFilters) => ({
      ...prevFilters,
      employeeId: user ? user._id : '',
      pageNo: 1,
      search: '', 
    }));
  };

  const handleDownloadClick = async (employeeId, reviewMonth, employee_name) => {
    const [month, year] = reviewMonth.split('-');
    const date = new Date(year, month - 1, 2);
    const formattedDate = date.toISOString().split('T')[0];

    try {
      const response = await getPmsPdf({ id: employeeId, date: formattedDate });
      if (response.status === 1 && response.data) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `PMS_${employee_name}_${reviewMonth}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        dispatch(callSnackBar('Failed to download PDF', SNACK_BAR_VARIETNS.error));
      }
    } catch (error) {
      console.error('Error downloading PDF:', error);
      dispatch(callSnackBar('Failed to download PDF', SNACK_BAR_VARIETNS.error));
    }
  };

  const handleInfoClick = async (employeeId, reviewMonth) => {
    try {
      const [month, year] = reviewMonth.split('-');
      const response = await getPmsPdf({ id: employeeId, date: `${year}-${month}-02` });
      if (response.status === 1 && response.data) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        window.open(url, '_blank');
      } else {
        dispatch(callSnackBar('Failed to open PDF', SNACK_BAR_VARIETNS.error));
      }
    } catch (error) {
      console.error('Error opening PDF:', error);
      dispatch(callSnackBar('Failed to open PDF', SNACK_BAR_VARIETNS.error));
    }
  };

  const columns = useMemo(
    () => [
      { id: 1, label: 'Employee Name', fieldName: 'employee_name', sort: true },
      { id: 2, label: 'Review Month', fieldName: 'review_month', sort: true },
      {
        id: 3,
        label: 'Actions',
        sort: false,
        renderValue: (rowData) => (
          <>
            <IconButton
              onClick={() => handleEditClick(rowData._id, rowData.review_month)}
            >
              <Edit color='info' />
            </IconButton>
              
                <IconButton
                  onClick={() => handleInfoClick(rowData._id, rowData.review_month)}
                  >
                  <Info color='info' />
                </IconButton>
                  {rowData.tasks.some((task) => task.status ) && (
                    <>
                <IconButton
                  onClick={() => handleDownloadClick(rowData._id, rowData.review_month, rowData.employee_name)}
                >
                  <Download />
                </IconButton>
              </>
            )}
          </>
        ),
      },
    ],
    [handleEditClick]
  );

  const startIndex = (filters.pageNo - 1) * filters.pageSize;
  const endIndex = startIndex + filters.pageSize;
  const currentForms = forms.slice(startIndex, endIndex);

  return (
    <>
     
      {/* {selectedEmployee.user && selectedEmployee.user != null && <BasicProfileDetailsComponent data={selectedEmployee.user} />} */}
      <EmployeeTaskReviewUi
        forms={currentForms}
        loading={loading}
        filters={filters}
        columns={columns}
        setFilters={setFilters}
        fetchForms={fetchForms}
        handleFormSubmit={handleFormSubmit}
        handleSearchChange={handleSearchChange}
        onUserChange={handleUserChange}
        defaultVal={selectedEmployee.user}
      />
    </>
  );

};

export default EmployeeTaskReviewController;