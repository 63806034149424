import { memo, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import ReimberesementCreateUI from "./ReimberesementCreateUI"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"


import { useNavigate, useParams } from "react-router-dom"
import { REIMBURSEMENT_TYPE, SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants"
import { addReimberesementApi } from "../../apis/reimbersement.api"

import moment from "moment"
import { callSnackBar } from "../../store/actions/snackbarAction"
import {  fetchPersonalReimbursementDataAction, fetchReimbursementCountAction } from "../../store/actions/reimbursementAction"


const ReimberesementCreateController = ({ userId, modal }) => {
    const user = useSelector(state => state.user);
    const validate = useValidate();
    const dispatch = useDispatch();
    const params = useParams();

    const [loading, setLoading] = useState(false);
    const { reimbursement } = useSelector((state) => state);

    const shouldDisableDate = (date) => {
        const currentDate = moment();
        const selectedDate = moment(date);
        const fifthOfCurrentMonth = currentDate.clone().date(5);

        // Disable dates of the previous month after the 5th of the current month
        return selectedDate.isBefore(currentDate.clone().startOf('month')) && currentDate.isAfter(fifthOfCurrentMonth);
    };
    const defaultData = {
        userId: userId ?? "",
        err: '',
        expenseDate: moment(),
        type: null,
        amount: 0,
        bills: [],
        used_from_advance: false,
        advance_payment_id: null,
        advance_payment_detail: null,
        place: '',
        purpose: '',
        noOfKMS: 0,
        vehichleType: '',
        foodType: '',
        expenseType: '',
        used_from_advance: null
    };
    const [fields, setFields] = useState(defaultData);
    const [files, setFiles] = useState([]);

    const validationSchema = useMemo(() => ([
        {
            required: true,
            value: fields.expenseDate,
            field: 'Date',
        },
        {
            required: true,
            value: fields.type,
            field: 'Expense Type',
        },
        {
            required: true,
            value: fields.amount,
            field: 'Amount',
        },
        // {
        //     value: fields.advance_payment_id,
        //     field: 'Advance Payment',
        //     custom: () => {
        //         if (fields.used_from_advance && (!fields.advance_payment_id || fields.advance_payment_id == '')) {
        //             return "Please select Your advance payment";
        //         } else if (fields.advance_payment_id && (fields.advance_payment_detail?.amount - fields.advance_payment_detail?.used_amount) < fields.amount) {
        //             return "You don't have available balance";
        //         }
        //         return true;
        //     }
        // },
        {
            value: fields.bills,
            field: 'Bills',
            custom: () => {
                if (fields.bills.length == 0 && (fields.type == REIMBURSEMENT_TYPE.food || fields.type == REIMBURSEMENT_TYPE.travel)) {
                    return "Please attach bill. It is required.";
                }
                return true;
            }
        },
    ]), [fields]);
    const navigate = useNavigate();

    const onSubmit = async (e) => {
        e.preventDefault();

        const validationResponse = validate(validationSchema);

        const currentDate = moment();
        const expenseDate = moment(fields.expenseDate);
        const fifthOfCurrentMonth = currentDate.clone().date(5);

        if (expenseDate.isBefore(currentDate.clone().startOf('month')) && currentDate.isAfter(fifthOfCurrentMonth)) {
            const errorMessage = "You cannot apply for reimbursement of the previous month .";
            setFields({ ...fields, err: errorMessage });
            dispatch(callSnackBar(errorMessage, SNACK_BAR_VARIETNS.error));
            return;
        }

        if (validationResponse === true) {
            setLoading(true);
            const passedData = { ...fields };
            passedData['expenseDate'] = passedData.expenseDate.valueOf();
            passedData['used_from_advance'] = passedData['used_from_advance'] == 'true' ? true : undefined;
            dispatch(
                callApiAction(
                    async () => await addReimberesementApi(passedData),
                    async (response) => {
                        navigate('/reimburesement/');
                        setLoading(false);
                        dispatch(fetchReimbursementCountAction(reimbursement.count_filters));
                        dispatch(fetchPersonalReimbursementDataAction(reimbursement.personal_reimbursement_filters));
                        dispatch(callSnackBar("Reimburesement applied successfully", SNACK_BAR_VARIETNS.suceess));
                    },
                    (err) => {
                        setLoading(false);
                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
                        setFields({ ...fields, err });
                    }
                )
            );
        } else {
            setFields({ ...fields, 'err': validationResponse });
            dispatch(callSnackBar(validationResponse, SNACK_BAR_VARIETNS.error));
        }
    }

    return <ReimberesementCreateUI modal={modal} defaultData={defaultData} setFiles={setFiles} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} shouldDisableDate={shouldDisableDate} />;
}

export default memo(ReimberesementCreateController);