import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  AccountCircle,
  Business,
  Cancel,
  FmdBad,
  PersonOff,
} from "@mui/icons-material";
import DailyUpdateCustomDayPicker from "../../components/layouts/common/DailyUpdateCustomDayPicker";
import { EmployeeTaskUserContext } from "./EmployeeMain";
import { DAILYUPDATE_STATUS, LEAVE_STATUS } from "../../utils/leave.constants";
import { useSelector } from "react-redux";
import { CenteredBox } from "../../components/layouts/common/boxes";
import { HALF_TYPE_OBJ } from "../../utils/constants";
import moment from "moment";

const EmployeeDailyUpdateUi = ({
  fetchMoreloading,
  date,
  setDate,
  loading,
  onReject,
  onApprove,
  handleUserCardClick,
  leaveList,
  leaveOnDate,
  fetchChildUsersOnClick,
  allUsers,
  onDeduct,
  lwpOnDate,
  Weeklyoff,
}) => {
  const scrollRef = useRef();
  const selectedUser = useContext(EmployeeTaskUserContext);
  const [buttonClicked, setButtonClicked] = useState(false);

  const holiday = useSelector((state) => state.holiday.data);

  const iscompancyholiday = holiday.some((data) =>
    moment(data.date).isSame(date, "day")
  );

  const handleUserClick = (userId, userName) => {
    fetchChildUsersOnClick(userId, userName);

    if (scrollRef.current) {
      setTimeout(() => {
        scrollRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 300);
    }
  };

  const renderUserCard = (user, key, userHierarchy, idx, selectedUserData) => {
    const isOnLeave = leaveOnDate?.some(
      (leave) =>
        leave.applied_by === user._id &&
        leave.status !== LEAVE_STATUS.REJECTED &&
        ((leave.from_time_duration === HALF_TYPE_OBJ.FULL_DAY &&
          leave.to_time_duration === HALF_TYPE_OBJ.FULL_DAY &&
          date.isBetween(leave.from, leave.to, "day", "[]")) ||
          leaveOnDate
            .filter(
              (l) =>
                l.applied_by === user._id &&
                l.status !== LEAVE_STATUS.REJECTED &&
                l.from === leave.from &&
                date.isSame(l.from, "day")
            )
            .some((l) => l.type === 1 && l.type === 2))
    );
    const userweekly = [];
    if (!selectedUserData && Array.isArray(Weeklyoff) && Weeklyoff.length >= 0 ) {

      Weeklyoff?.map((week) => {
        if (week._id === user._id) {
          week?.weekly_of?.map((item) => {
            userweekly?.push(item);
          });
        }
      });
     
    }
    let week = userweekly?.some((item) => {
      return moment(date).day() === item;
    });
    const isLwp = lwpOnDate?.some((tempLwp) => tempLwp?.user_id === user?._id);

    const hasNoUpdates = !user.tasks || user.tasks.length === 0;
    const isToday = moment().diff(moment(date), "day") < 2;
    const isFutureDate = date.isAfter(moment(), "day");

    return (
      <Grid item xs={12} sm={6} md={4} key={key}>
        <Card
          sx={{
            height: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            borderRadius: "12px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            border: "1px solid #ddd",
            transition: "box-shadow 0.3s ease-in-out",
            "&:hover": {
              boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          <CardContent sx={{ overflowY: "auto", padding: "8px 12px" }}>
            {/* Header Section */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "4px 8px",
                borderRadius: "4px",
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#f9f9f9",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AccountCircle sx={{ fontSize: 20, mr: 1 }} />
                  {selectedUser ? selectedUser?.name : user?.name}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 0.5,
                  }}
                >
                  <Business sx={{ fontSize: 18, mr: 1 }} />
                  {user.departmentName || "No Department"}
                </Typography>
              </Box>

              {!isOnLeave &&
                hasNoUpdates &&
                !isToday &&
                !week &&
                !iscompancyholiday && (
                  <Button
                    disabled={isLwp}
                    variant={buttonClicked ? "contained" : "outlined"}
                    onClick={() => {
                      onDeduct(user);
                    }}
                    sx={{
                      color: buttonClicked ? "#fff" : "#f44336",
                      borderColor: "#f44336",
                      backgroundColor: buttonClicked
                        ? "#f44336"
                        : "transparent",
                      "&:hover": {
                        backgroundColor: buttonClicked
                          ? "#f44336"
                          : "transparent",
                        borderColor: "#f44336",
                      },
                    }}
                  >
                    <Typography> LWP</Typography>
                  </Button>
                )}
            </Box>

            {user.tasks?.length > 0 ? (
              user.tasks.map((task, i) => (
                <React.Fragment key={i}>
                  <Box
                    mt={2}
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderRadius: "10px",
                      alignItems: "center",
                      backgroundColor:
                        task?.status === DAILYUPDATE_STATUS.REJECTED
                          ? "rgba(244, 67, 54, 0.1)"
                          : "#f9f9f9",
                    }}
                  >
                    <Typography variant="body2" sx={{ flexGrow: 1 }}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            task.description || "No description available.",
                        }}
                      />
                    </Typography>
                    {task?.status !== DAILYUPDATE_STATUS.REJECTED && (
                      <IconButton
                        color="error"
                        onClick={() => {
                          if (selectedUser) {
                            onReject({ task_id: task?._id }, user._id, 0, 0);
                          } else {
                            onReject(
                              { task_id: task?._id },
                              userHierarchy.parentUserId,
                              userHierarchy.parentUserName,
                              idx
                            );
                          }
                        }}
                      >
                        <Cancel />
                      </IconButton>
                    )}
                  </Box>

                  {i < user?.tasks?.length - 1 && <Divider sx={{ my: 1 }} />}
                </React.Fragment>
              ))
            ) : isOnLeave ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "red",
                    mt: 2,
                  }}
                >
                  Employee on leave.
                </Typography>
              </Box>
            ) : iscompancyholiday ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px",
                  position: "relative",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#999",
                    mt: 2,
                  }}
                >
                  Company Holiday
                </Typography>
              </Box>
            ) : // moment(date).day() === 0
            week ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px",
                  position: "relative",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#999",
                    mt: 2,
                  }}
                >
                  Weekly Of
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px",
                  position: "relative",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#999",
                    mt: 2,
                  }}
                >
                  No Update Found.
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const renderAllUsers = () => {
    if (
      allUsers.length === 0 ||
      allUsers.every((userHierarchy) => userHierarchy.childUsers.length === 0)
    ) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100px",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#999",
              textAlign: "center",
            }}
          >
            No users found.
          </Typography>
        </Box>
      );
    }

    return allUsers.map((userHierarchy, idx) => {
      const shouldRenderParentBox =
        userHierarchy.parentUserName && userHierarchy.childUsers.length > 0;

      return (
        <Box
          key={idx}
          ref={idx === allUsers.length - 1 ? scrollRef : null}
          mt={4}
        >
          {shouldRenderParentBox && (
            <Box
              mb={2}
              p={2}
              height={"6vh"}
              width={"100%"}
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "lightgrey",
                borderRadius: "5px",
              }}
            >
              <AccountCircle color="primary" sx={{ fontSize: 22, mr: 1 }} />
              <Typography
                variant="h4"
                color={"primary"}
                sx={{ fontWeight: 700 }}
              >
                {userHierarchy.parentUserName}
              </Typography>
            </Box>
          )}

          <Grid container spacing={3}>
            {userHierarchy.childUsers.length > 0 ? (
              userHierarchy.childUsers.map((user, index) =>
                renderUserCard(user, index, userHierarchy, idx)
              )
            ) : (
              <Grid item xs={12}></Grid>
            )}
          </Grid>
        </Box>
      );
    });
  };
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box mt={3} mb={3} position="relative">
      {!isMobile && (
        <Box mb={2}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "40px",
              color: "#0D0D0D",
            }}
          >
            Employee Daily Updates
          </Typography>
        </Box>
      )}

      <>
        <Box id="custom-day-picker">
          <DailyUpdateCustomDayPicker date={date} setDate={setDate} />
        </Box>
      </>
      {loading ? (
        <CenteredBox sx={{ minHeight: "300px" }}>
          <CircularProgress />
        </CenteredBox>
      ) : (
        <>
          {selectedUser
            ? (() => {
                const selectedUserData = allUsers
                  .flatMap((userHierarchy) => userHierarchy.childUsers)
                  .filter((user) => user._id === selectedUser._id);

                const isOnLeave = leaveOnDate.some(
                  (leave) =>
                    leave.applied_by === selectedUser._id &&
                    leave.from_time_duration === HALF_TYPE_OBJ.FULL_DAY &&
                    leave.to_time_duration === HALF_TYPE_OBJ.FULL_DAY &&
                    leave.status !== LEAVE_STATUS.REJECTED &&
                    date.isBetween(leave.from, leave.to, "day", "[]")
                );

                return (
                  <Box mt={4}>
                    {isOnLeave ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "100px",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            color: "red",
                            textAlign: "center",
                          }}
                        >
                          Employee on leave.
                        </Typography>
                      </Box>
                    ) : iscompancyholiday && selectedUserData.length === 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100px",
                          position: "relative",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            color: "#999",
                            mt: 2,
                          }}
                        >
                          Company Holiday
                        </Typography>
                      </Box>
                    ) : moment(date).day() === 0 &&
                      selectedUserData.length === 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100px",
                          position: "relative",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            color: "#999",
                            mt: 2,
                          }}
                        >
                          Weekly Of
                        </Typography>
                      </Box>
                    ) : selectedUserData.length === 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "100px",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            color: "#999",
                            textAlign: "center",
                          }}
                        >
                          No update found.
                        </Typography>
                      </Box>
                    ) : (
                      <Grid container spacing={3} direction="row">
                        {selectedUserData.map((user, index) =>
                          renderUserCard(user, index, selectedUserData)
                        )}
                      </Grid>
                    )}
                  </Box>
                );
              })()
            : renderAllUsers()}
        </>
      )}
      {fetchMoreloading && !loading && (
        <CenteredBox sx={{ minHeight: "100px" }}>
          <CircularProgress />
        </CenteredBox>
      )}
    </Box>
  );
};

export default EmployeeDailyUpdateUi;
