// import { memo, useEffect, useMemo, useState } from 'react'
// import useValidate from '../../../store/hooks/useValidator'

// import { useDispatch, useSelector } from 'react-redux'
// import { callApiAction } from '../../../store/actions/commonAction'

// import { closeModal, openModal } from '../../../store/actions/modalAction'

// import LeaveRaiseUi from './LeaveRaiseUi'
// import { raiseLeaveApi } from '../../../apis/leave.api'
// import moment from 'moment'
// import { HALF_TYPE, HALF_TYPE_OBJ } from '../../../utils/constants'
// import { useNavigate } from 'react-router-dom'
// import { LEAVE_NAMES, LEAVE_TYPE } from '../../../utils/leave.constants'
// import LeavePreview from './LeavePreview'
// import { fetchLeaveBalanceCountAction } from '../../../store/actions/leaveBalanceAction'

// const LeaveRaiseController = ({ userId, weeklyOf, modal, callBack = () => {}, join_date }) => {
//   const validate = useValidate();
//   const dispatch = useDispatch();

//   const { leaveBalance, holiday, user } = useSelector((state) => state);
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);

//   const [fields, setFields] = useState({
//     err: '',
//     applicationDate: moment(),
//     userId: userId,
//     type: null,
//     remarks: '',
//     from: moment(),
//     to: moment(),
//     from_time_duration: HALF_TYPE_OBJ.FULL_DAY,
//     to_time_duration: HALF_TYPE_OBJ.FULL_DAY,
//     availableLeave: 0,
//     moreThanOneDay: false,
//     leave_count: 0,
//   });

//   const [appliedLeaves, setAppliedLeaves] = useState({});

//   const validationSchema = useMemo(
//     () => [
//       {
//         value: fields.type,
//         custom: () => {
//           if (fields.leave_count > 1 || fields.type === LEAVE_TYPE.LWP) {
//             return true;
//           } else {
//             return fields.type && fields.type !== '';
//           }
//         },
//         field: 'Leave Type',
//       },
//       {
//         required: true,
//         value: fields.from,
//         field: 'Leave From Date',
//         custom: () => {
//           return !disableDates(fields.from);
//         },
//       },
//       {
//         required: true,
//         value: fields.to,
//         field: 'Leave To Date',
//         custom: () => {
//           return !disableDates(fields.to);
//         },
//       },
//       {
//         required: true,
//         value: fields.remarks,
//         field: 'Remarks',
//       },
//     ],
//     [fields],
//   );

//   const disableDates = (date) => {
//     let isHoliday = false;
//     if (holiday.data && Array.isArray(holiday.data)) {
//       for (let i = 0; i < holiday.data.length; i++) {
//         const item = holiday.data[i];
//         const incomingDate = moment(item.date);
//         isHoliday =
//           incomingDate.get('dates') === date.get('dates') &&
//           incomingDate.get('months') === date.get('months') &&
//           incomingDate.get('years') === date.get('years');
//         if (isHoliday) break;
//       }
//     }
//     let isWeeklyOf = false;
//     if (!isHoliday) {
//       if (weeklyOf && Array.isArray(weeklyOf) && weeklyOf.includes(date.day())) {
//         isWeeklyOf = true;
//       } else if (user.data && user.data.weekly_of && Array.isArray(user.data.weekly_of) && user.data.weekly_of.includes(date.day())) {
//         isWeeklyOf = true;
//       }
//     }
//     return isWeeklyOf || isHoliday;
//   };

//   const onLeaveApply = async (e, submit) => {
//     if (holiday.loading) {
//       return;
//     }
//     e?.preventDefault?.();

//     const validationResponse = validate(validationSchema);
//     console.log("joinDate",user.data.join_date)
//     const joinMonth = moment(user.data.join_date).format('MM-YYYY');
//     const fromMonth = moment(fields.from).format('MM-YYYY');
//     const toMonth = moment(fields.to).format('MM-YYYY');

//     if (joinMonth === fromMonth || joinMonth === toMonth) {
//       setFields({ ...fields, err: 'You cannot apply for leave in your joining month.' });
//       return;
//     }

//     if (validationResponse === true) {
//       const leaveData = { ...fields, view: !(submit === true) };

//       leaveData.from = fields.from.format('YYYY-MM-DD');
//       leaveData.to = fields.to.format('YYYY-MM-DD');

//       if (!leaveData.type) delete leaveData['type'];

//       setLoading(true);
//       dispatch(
//         callApiAction(
//           async () => await raiseLeaveApi(leaveData),
//           async (response) => {
//             if (!(submit === true)) {
//               dispatch(
//                 openModal({
//                   component: (
//                     <LeavePreview
//                       disableDates={disableDates}
//                       loading={loading}
//                       onClose={() => {
//                         setAppliedLeaves({});
//                         dispatch(closeModal());
//                       }}
//                       data={response}
//                       onSubmit={onConfirmLeaveApply}
//                     />
//                   ),
//                   title: 'Leave Preview',
//                 }),
//               );
//               setAppliedLeaves(response);
//             } else {
//               dispatch(fetchLeaveBalanceCountAction({ userId, date: moment().valueOf() }));
//               dispatch(closeModal());
//               navigate('/calendar/');
//             }
//             setLoading(false);
//           },
//           (err) => {
//             setLoading(false);
//             setFields({ ...fields, err });
//           },
//         ),
//       );
//     } else {
//       setFields({ ...fields, err: validationResponse });
//     }
//   };

//   const onConfirmLeaveApply = async (e) => {
//     dispatch(closeModal());
//     onLeaveApply(e, true);
//   };

//   const genrateLeaveCount = () => {
//     const startseconds = fields.from.valueOf();
//     const endseconds = fields.to.valueOf();

//     const millisecondsPerDay = 1000 * 60 * 60 * 24;

//     const count = (endseconds - startseconds) / millisecondsPerDay;

//     let deductCount = 0;

//     if (fields.from.get('date') !== fields.to.get('date') && fields.to_time_duration) {
//       HALF_TYPE.forEach((half) => {
//         if (fields.to_time_duration === half.value) {
//           deductCount -= half.deduct;
//         }
//       });
//     }

//     if (fields.from_time_duration) {
//       HALF_TYPE.forEach((half) => {
//         if (fields.from_time_duration === half.value) {
//           deductCount += half.add;
//         }
//       });
//     }

//     const loopStart = moment()
//       .set('years', fields.from.get('years'))
//       .set('months', fields.from.get('months'))
//       .set('dates', fields.from.get('dates'))
//       .set('hours', 0)
//       .set('minutes', 0)
//       .set('seconds', 0)
//       .set('milliseconds', 0)
//       .valueOf();
//     const loopEnd = moment()
//       .set('years', fields.to.get('years'))
//       .set('months', fields.to.get('months'))
//       .set('dates', fields.to.get('dates'))
//       .set('hours', 0)
//       .set('minutes', 0)
//       .set('seconds', 0)
//       .set('milliseconds', 0)
//       .valueOf();

//     for (let i = loopStart; i <= loopEnd; i += millisecondsPerDay) {
//       if (disableDates(moment(i))) {
//         deductCount -= 1;
//       }
//     }

//     setFields({
//       ...fields,
//       leave_count: parseFloat((count + deductCount).toFixed(1)),
//     });
//   };

//   useEffect(() => {
//     genrateLeaveCount();
//   }, [
//     fields.from_time_duration,
//     fields.to_time_duration,
//     fields.to,
//     fields.from,
//   ]);

//   return (
//     <LeaveRaiseUi
//       modal={modal}
//       disableDates={disableDates}
//       loading={loading}
//       fields={fields}
//       onSubmit={onLeaveApply}
//       setFields={setFields}
//     />
//   );
// };

// export default memo(LeaveRaiseController);
import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../../store/hooks/useValidator";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";
import { closeModal, openModal } from "../../../store/actions/modalAction";
import LeaveRaiseUi from "./LeaveRaiseUi";
import { raiseLeaveApi } from "../../../apis/leave.api";
import moment from "moment";
import { HALF_TYPE, HALF_TYPE_OBJ } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { LEAVE_NAMES, LEAVE_TYPE } from "../../../utils/leave.constants";
import LeavePreview from "./LeavePreview";
import { fetchLeaveBalanceCountAction } from "../../../store/actions/leaveBalanceAction";

const LeaveRaiseController = ({ userId, weeklyOf, modal }) => {
  const validate = useValidate();
  const dispatch = useDispatch();

  const { leaveBalance, holiday, user } = useSelector((state) => state);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    err: "",
    applicationDate: moment(),
    userId: userId,
    type: null,
    remarks: "",
    from: moment(),
    to: moment(),
    from_time_duration: HALF_TYPE_OBJ.FULL_DAY,
    to_time_duration: HALF_TYPE_OBJ.FULL_DAY,
    availableLeave: 0,
    moreThanOneDay: false,
    leave_count: 0,
  });

  const [appliedLeaves, setAppliedLeaves] = useState({});

  const validationSchema = useMemo(
    () => [
      {
        value: fields.type,
        custom: () => {
          if (fields.leave_count > 1 || fields.type === LEAVE_TYPE.LWP) {
            return true;
          } else {
            return fields.type && fields.type !== "";
          }
        },
        field: "Leave Type",
      },
      {
        required: true,
        value: fields.from,
        field: "Leave From Date",
        custom: () => {
          return !disableDates(fields.from);
        },
      },
      {
        required: true,
        value: fields.to,
        field: "Leave To Date",
        custom: () => {
          return !disableDates(fields.to);
        },
      },
      {
        required: true,
        value: fields.remarks,
        field: "Remarks",
      },
    ],
    [fields]
  );

  const disableDates = (date) => {
    let isHoliday = false;
    if (holiday.data && Array.isArray(holiday.data)) {
      for (let i = 0; i < holiday.data.length; i++) {
        const item = holiday.data[i];
        const incomingDate = moment(item.date);
        isHoliday =
          incomingDate.get("dates") === date.get("dates") &&
          incomingDate.get("months") === date.get("months") &&
          incomingDate.get("years") === date.get("years");
        if (isHoliday) break;
      }
    }
    let isWeeklyOf = false;
    if (!isHoliday) {
      if (
        weeklyOf &&
        Array.isArray(weeklyOf) &&
        weeklyOf.includes(date.day())
      ) {
        isWeeklyOf = true;
      } else if (
        user.data &&
        user.data.weekly_of &&
        Array.isArray(user.data.weekly_of) &&
        user.data.weekly_of.includes(date.day())
      ) {
        isWeeklyOf = true;
      }
    }
    return isWeeklyOf || isHoliday;
  };

  const onLeaveApply = async (e, submit) => {
    if (holiday.loading) {
      return;
    }
    e?.preventDefault?.();

    const validationResponse = validate(validationSchema);
    console.log("testing", user.data.join_date);

    // Convert the join date and from/to dates to moment objects
    const joinDateMoment = moment(user.data.join_date);
    const fromDateMoment = moment(fields.from);
    const toDateMoment = moment(fields.to);

    // Calculate the date one month after the join date
    const oneMonthAfterJoinDate = joinDateMoment.clone().add(1, "month");

    // Function to check if a date is before the one month after the join date
    const isWithinOneMonthOfJoining = (date) =>
      date.isBefore(oneMonthAfterJoinDate, "day");

    // Check if the from date or to date is within one month of joining
    if (
      isWithinOneMonthOfJoining(fromDateMoment) ||
      isWithinOneMonthOfJoining(toDateMoment)
    ) {
      setFields({
        ...fields,
        err: "You cannot apply for leave within one month of your joining date.",
      });
      return;
    }

    // If validation passes, proceed with the rest of your logic

    if (validationResponse === true) {
      const leaveData = { ...fields, view: !(submit === true) };

      leaveData.from = fields.from.format("YYYY-MM-DD");
      leaveData.to = fields.to.format("YYYY-MM-DD");

      if (!leaveData.type) delete leaveData["type"];

      setLoading(true);
      dispatch(
        callApiAction(
          async () => await raiseLeaveApi(leaveData),
          async (response) => {
            if (!(submit === true)) {
              dispatch(
                openModal({
                  component: (
                    <LeavePreview
                      disableDates={disableDates}
                      loading={loading}
                      onClose={() => {
                        setAppliedLeaves({});
                        dispatch(closeModal());
                      }}
                      data={response}
                      onSubmit={onConfirmLeaveApply}
                    />
                  ),
                  title: "Leave Preview",
                })
              );
              setAppliedLeaves(response);
            } else {
              dispatch(
                fetchLeaveBalanceCountAction({
                  userId,
                  date: moment().valueOf(),
                })
              );
              dispatch(closeModal());
              navigate("/calendar/");
            }
            setLoading(false);
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onConfirmLeaveApply = async (e) => {
    dispatch(closeModal());
    onLeaveApply(e, true);
  };

  const genrateLeaveCount = () => {
    const startseconds = fields.from.valueOf();
    const endseconds = fields.to.valueOf();

    const millisecondsPerDay = 1000 * 60 * 60 * 24;

    const count = (endseconds - startseconds) / millisecondsPerDay;

    let deductCount = 0;

    if (
      fields.from.get("date") !== fields.to.get("date") &&
      fields.to_time_duration
    ) {
      HALF_TYPE.forEach((half) => {
        if (fields.to_time_duration == half.value) {
          deductCount -= half.deduct;
        }
      });
    }

    if (fields.from_time_duration) {
      HALF_TYPE.forEach((half) => {
        if (fields.from_time_duration == half.value) {
          deductCount += half.add;
        }
      });
    }

    const loopStart = moment()
      .set("years", fields.from.get("years"))
      .set("months", fields.from.get("months"))
      .set("dates", fields.from.get("dates"))
      .set("hours", 0)
      .set("minutes", 0)
      .set("seconds", 0)
      .set("milliseconds", 0)
      .valueOf();
    const loopEnd = moment()
      .set("years", fields.to.get("years"))
      .set("months", fields.to.get("months"))
      .set("dates", fields.to.get("dates"))
      .set("hours", 0)
      .set("minutes", 0)
      .set("seconds", 0)
      .set("milliseconds", 0)
      .valueOf();

    for (let i = loopStart; i <= loopEnd; i += millisecondsPerDay) {
      if (disableDates(moment(i))) {
        deductCount -= 1;
      }
    }

    setFields({
      ...fields,
      leave_count: parseFloat((count + deductCount).toFixed(1)),
    });
  };

  useEffect(() => {
    genrateLeaveCount();
  }, [
    fields.from_time_duration,
    fields.to_time_duration,
    fields.to,
    fields.from,
  ]);

  return (
    <LeaveRaiseUi
      modal={modal}
      disableDates={disableDates}
      loading={loading}
      fields={fields}
      onSubmit={onLeaveApply}
      setFields={setFields}
    />
  );
};

export default memo(LeaveRaiseController);
