import { Add, Download } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ActionButton } from "../../components/button/SubmitButton";
import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker";

import ReimburesmentCounts from "./ReimburesmentCounts";
import DataTable from "../../components/tables/DataTable";
import AllReimburesementApprovalButton from "./actionButtons/AllReimburesmentApprovalButtons";
import { REIMBURSEMENT_STATUS } from "../../utils/constants";
import DownloadPdfButton from "./actionButtons/DownloadPdfButton";
import { useEffect } from "react";

const ReimberesementPageUI = ({
  selectedUser,
  isForEmployee,
  counts,
  filters,
  setFilters,
  columns,
  list,
  loading,
  listLoading,
  callBack,
}) => {
  const user = useSelector((state) => state.user);

  useEffect(() => {
    console.log("this is countdata", counts);
  }, [counts]);
  return (
    <>
      <Box mt={3} mb={3}>
        {
          <Grid
            flexWrap="wrap-reverse"
            container
            columnSpacing={2}
            rowSpacing={2}
          >
            <Grid item sm={isForEmployee ? 9.5 : 7} xs={12}>
              <CustomMonthPicker
                date={filters.date}
                setDate={(date) => {
                  setFilters({ ...filters, date });
                }}
              />
            </Grid>
            <Grid item sm={isForEmployee ? 2.5 : 5} xs={12}>
              <Box sx={{ display: "flex" }}>
                <DownloadPdfButton
                  selectedUser={selectedUser}
                  date={filters.date}
                />
                <Box ml={1} />
                {!isForEmployee && (
                  <ActionButton
                    size="small"
                    LinkComponent={Link}
                    to="apply-reimbursement"
                    title="Add Expense"
                    icon={<Add />}
                  ></ActionButton>
                )}
              </Box>
            </Grid>
          </Grid>
        }
        <Box mt={4}>
          <ReimburesmentCounts
            filters={filters}
            setFilters={setFilters}
            data={counts}
            loading={loading}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }} mt={4}>
          <Box>
            <AllReimburesementApprovalButton
              callBack={callBack}
              size="small"
              color="info"
              date={filters.date?.toISOString()}
              selectedUser={selectedUser}
              status={REIMBURSEMENT_STATUS.paid}
              text="Mark All As Paid"
            />
          </Box>
          <Box ml={1}>
            <AllReimburesementApprovalButton
              callBack={callBack}
              size="small"
              color="success"
              date={filters.date?.toISOString()}
              selectedUser={selectedUser}
              status={REIMBURSEMENT_STATUS.approved}
              text="Mark All As Approved"
            />
          </Box>
        </Box>
        <Box sx={{ minHeight: "300px" }} mt={3}>
          <DataTable
            columns={columns}
            rows={list?.result ?? []}
            count={list?.total}
            filters={filters}
            setFilters={setFilters}
            loading={listLoading}
          />
        </Box>
      </Box>
    </>
  );
};

export default ReimberesementPageUI;
