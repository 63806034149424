import { Outlet } from "react-router"
import EmployeeTaskButtons from "./EmployeeTaskButtons"
import { Box, Paper, useMediaQuery } from "@mui/material"
import BirthdayContainer from "../birthday/BirthdayContainer"

const TaskMain = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    return <>
       
        <EmployeeTaskButtons />
        <Paper component={Box} mb={4} p={isMobile ? 2 : 3} sx={(theme) => ({ border: "1px solid " + theme.palette.grey[500] })}>
            <Outlet />
        </Paper>
    </>
}
export default TaskMain