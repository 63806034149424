import React, { useEffect, useState } from "react";
import { createHoliday } from "../../apis/holiday.api";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
// import { fetchDepartments, createDepartment, updateDepartment } from "../../apis/department";
import {
  createLeaveType,
  updateLeaveType,
  fetchLeaveTypes,
} from "../../apis/leaveType.api";
import UpdateLeaveTypeUI from "./UpdateLeaveTypeUI";
import { closeModal } from "../../store/actions/modalAction";
import { setDepartmentDataOnDeleteAction } from "../../store/actions/settingsAction";

const UpdateLeaveTypeController = ({ id,initialname,initialcolor,callBack,initialsystem_default,initialshort_name,initialidentifier,initialpriority}) => {

  const dispatch = useDispatch();
  const updateLeaveTypeApi = updateLeaveType;
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState(initialcolor);
  const [short_name, setShort_name] = useState(initialshort_name);
  const [system_default, setSystem_default] = useState(initialsystem_default);
  const [name, setName] = useState(initialname);
  const [err, setErr] = useState("");
  const [identifier, setIdentifier] = useState(initialidentifier);
  const [priority, setPriority] = useState(initialpriority);
  const fetchLeaveTypesApi = fetchLeaveTypes;

//   const fetchList = () => {
//     setLoading(true);

//     dispatch(
//       callApiAction(
//         async () => await fetchLeaveTypesApi(filters),
//         (response) => {
//           console.log(response);
//           setState(response);
//           setLoading(false);
//         },

//         (err) => {
//           setLoading(false);
//         }
//       )
//     );
//   };
  const UpdateLeaveType = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await updateLeaveTypeApi({ id, name,color,system_default,short_name,identifier,priority }),
        (response) => {
          dispatch(closeModal());
          callBack({
            id,
            name,
            color,
            system_default,
            short_name,
            identifier,
            priority
          });
        //   const updatedData = settings.department_data.map((item) =>
        //     item._id == id ? { ...item, name: name } : item
        //   );
        //   dispatch(
        //     setDepartmentDataOnDeleteAction(
        //       updatedData,
        //       settings.department_filters
        //     )
        //   );
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          setErr(err);
        }
      )
    );
  };
//   useEffect(() => {
//     fetchList();
//   }, [string]);
  return (
    <UpdateLeaveTypeUI
    loading={loading}
    UpdateLeaveType={UpdateLeaveType}
    color={color}
    setColor={setColor}
  
    setName={setName}
    name={name}
    short_name={short_name}
    setShort_name={setShort_name}
    system_default={system_default}
    setSystem_default={setSystem_default}
    identifier={identifier}
    setIdentifier={setIdentifier}
    priority={priority}
    setPriority={setPriority}
    err={err}
    />
  );
};

export default UpdateLeaveTypeController;
