import { memo, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../../store/actions/commonAction'

import { openModal } from '../../../../store/actions/modalAction'


import Calendar from '../../../../components/layouts/Calendar'
import CalendarBox from './CalendarInnerBox'

import {
  CALENDAR_ITEM_TYPES,
  LEAVE_COLORS,
  LEAVE_RESPONSE,
  SNACK_BAR_VARIETNS,

} from '../../../../utils/constants'
import { fetchTasksCalendarApi } from '../../../../apis/task.api'
import moment from 'moment'
import { EmployeeTaskUserContext } from '../../EmployeeMain'
import CustomMonthPicker from '../../../../components/layouts/common/CustomMonthPicker'
import { Box, useMediaQuery } from '@mui/material'
import TaskDetailsController from '../TaskDetails.controller'
import { callSnackBar } from '../../../../store/actions/snackbarAction'
import { getAllDailyUpdates } from '../../../../apis/dailyupdate.api'
import { getAllPms, getPmsPdf } from '../../../../apis/pms.api'
import PmsDetailsController from '../PmsDetailsController'
import { stripHtml } from 'string-strip-html'
import DailyUpdateDetailsController from '../DailyUpdateDetailsController'
import SubmitButton from '../../../../components/button/SubmitButton'

const TaskCalendarController = ({ Component, isFromPerfomance }) => {
  const { holiday, user } = useSelector((state) => state);
  const selectedUser = useContext(EmployeeTaskUserContext);
  const [calendarTimeObj, setCalendarTimeObj] = useState(moment());

  const weeklyOf = selectedUser ? selectedUser.weekly_of : user.data.weekly_of;
  const userId = selectedUser ? selectedUser._id : user.data._id;
  const userName = selectedUser
  ? `${selectedUser.first_name} ${selectedUser.last_name}`.trim() 
  : `${user.data.first_name} ${user.data.last_name}`.trim(); 
  
  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(false);

  const holidayList = holiday.data;
  const [taskList, setTaskList] = useState(null);

  // For PMS and daily updates
  const [pmsTaskList, setPmsTaskList] = useState(null);
  const [updatesList, setUpdateList] = useState(null);

  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    userId: userId,
    isFromPerfomance,
  });

  const fetchList = () => {
    setListLoading(true);

    const paramsToPass = { ...filters, userId: userId };

    // Fetch PMS Tasks
    dispatch(
      callApiAction(
        async () => await getAllPms({ userId, date: calendarTimeObj.toISOString() }),
        (response) => {
          setPmsTaskList(Array.isArray(response) ? response : []);
        },
        (err) => {
          setPmsTaskList([]);
          dispatch(callSnackBar('Server Error', SNACK_BAR_VARIETNS.error));
        }
      )
    );

    // Fetch other tasks
    dispatch(
      callApiAction(
        async () =>
          await fetchTasksCalendarApi({
            ...paramsToPass,
            date: calendarTimeObj.valueOf(),
          }),
        (response) => {
          setTaskList(response);
        },
        (err) => {
          setTaskList(null);
          dispatch(callSnackBar('Server Error', SNACK_BAR_VARIETNS.error));
        }
      )
    );

    // Fetch daily updates
    dispatch(
      callApiAction(
        async () =>
          await getAllDailyUpdates({
            ...paramsToPass,
            date: calendarTimeObj.toISOString(),
          }),
        (response) => {
          setUpdateList(response);
        },
        (err) => {
          setUpdateList(null);
          dispatch(callSnackBar('Server Error', SNACK_BAR_VARIETNS.error));
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [calendarTimeObj.valueOf(), userId]);

  useEffect(() => {
    if (taskList && updatesList && pmsTaskList) {
      let temp = [];

      // Process PMS tasks
      pmsTaskList.forEach((pmsItem) => {
        if (!userId || pmsItem.user_id._id === userId) {
          const taskDescription = pmsItem.monthly_task || '';
          const taskWords = taskDescription.split(' ').slice(0, 2).join(' ');
          const title = `${taskWords}`;

          temp.push({
            title,
            start: new Date(pmsItem.completion_date),
            end: new Date(pmsItem.completion_date),
            relatedId: pmsItem._id,
            type: CALENDAR_ITEM_TYPES.pms_task,
            onClick: () => {
              dispatch(
                openModal({
                  title: 'PMS Task Detail',
                  component: (
                    <PmsDetailsController
                      callBack={() => {}}
                      selectedUser={selectedUser}
                      id={pmsItem.user_id}
                      task={pmsItem}
                    />
                  ),
                  size: 'md',
                })
              );
            },
          });
        }
      });

      // Process other tasks
      taskList.forEach((item) => {
        temp.push({
          title: item.name,
          completed_date: item.completed_date ? new Date(item.completed_date) : null,
          start: new Date(item.date),
          end: new Date(item.date),
          relatedId: item._id,
          type: CALENDAR_ITEM_TYPES.task,
          status: item.status,
          priority: item.priority,
          onClick: () => {
            dispatch(
              openModal({
                title: 'Task Detail',
                component: <TaskDetailsController callBack={() => {}} selectedUser={selectedUser} id={item._id} />,
                size: 'md',
              })
            );
          },
        });
      });

      // Process daily updates
      const dailyUpdateMap = {}; 
      updatesList.forEach((item) => {
          const userIdKey = item.user_id; 
          const description = item.items[0]?.description || ''; 
          const plainText = stripHtml(description).result; 
          const updateTitle = plainText.split(' ').slice(0, 3).join(' '); 
          const dateKey = new Date(item.date).toDateString(); 

          // Create a unique key combining user ID and date
          const uniqueKey = `${userIdKey}-${dateKey}`;
          if (!dailyUpdateMap[uniqueKey]) {
              dailyUpdateMap[uniqueKey] = {
                  title: updateTitle,
                  dailyUpdate: true,
                  start: new Date(item.date),
                  end: new Date(item.date),
                  type: CALENDAR_ITEM_TYPES.daily_updates,
              };
          }
      });

      // Convert the map back to an array and add it to temp
      temp.push(...Object.values(dailyUpdateMap));


      // Process holidays
      if (holidayList && Array.isArray(holidayList)) {
        holidayList.forEach((leave) => {
          temp.push({
            title: leave.name,
            start: new Date(leave.date),
            end: new Date(leave.date),
            relatedId: leave._id,
            type: CALENDAR_ITEM_TYPES.holiday,
            color: LEAVE_COLORS.HOLIDAY,
            onClick: () => {},
          });
        });
      }

      setList(temp);
      setListLoading(false);
    }
  }, [taskList, updatesList, pmsTaskList]);
  const handleDownloadClick = async (userId,  userName) => {
  
    try {
      const response = await getPmsPdf({ id: userId, date: calendarTimeObj });
      if (response.status === 1 && response.data) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `PMS_${userName}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        dispatch(callSnackBar('Failed to download PDF', SNACK_BAR_VARIETNS.error));
      }
    } catch (error) {
      console.error('Error downloading PDF:', error);
      dispatch(callSnackBar('Failed to download PDF', SNACK_BAR_VARIETNS.error));
    }
  };
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <>
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }} mb={4} >
        <Box sx={{ flex: 1, display: 'flex'}}>
          <CustomMonthPicker date={calendarTimeObj} setDate={setCalendarTimeObj} />
        </Box>
          <Box sx={{ height: '100%', display: 'flex', mt: isMobile ? 2 : 0 }} ml={isMobile ? 2 : 3} mr={isMobile ? 2 : 0}>
         <SubmitButton 
            loading={listLoading}
        title="PMS form download"
        onClick={() => handleDownloadClick(userId, calendarTimeObj.format('MM-YYYY'), userName)} />
      
                </Box>
            </Box>
      <Calendar
        loading={listLoading}
        data={list}
        dayComponent={(props) =>
          Component ? (
            <Component weeklyOf={weeklyOf ?? [0]} {...props} calendarTimeObj={calendarTimeObj} />
          ) : (
            <CalendarBox weeklyOf={weeklyOf ?? [0]} {...props} calendarTimeObj={calendarTimeObj} />
          )
        }
        calendarTimeObj={calendarTimeObj.toDate()}
      />
    </>
  );
};

export default memo(TaskCalendarController);