import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";


export const downloadAppointmentApi = async params => {
  const callResponse = await axios({
      url: endpoints.FetchAppointmentLetter,
      method: "get",
      headers: getHeaders(),
      params,
      responseType: "blob"
  })
      .then(response => response.data)
      .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

  return callResponse;
};