import { Box, Button, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import UserSearchBar from "../../components/inputs/SearchBar";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import DataTable from "../../components/tables/DataTable";
import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker";
import { getUserApi } from "../../apis/user.api";
import FileDownloadComponent from "../../components/FileDownloadComponent";

const AppointmentMainUi = ({
  loading,
  filters,
  setFilters,
  fields,
  setFields,
  columns,
  state,
  downloadList,
  onUserChange,
  params,
}) => {
  const [date, setDate] = useState(moment());

  return (
    <Box m={3} sx={{ marginTop: "10px" }}>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "40px",
          color: "#0D0D0D",
        }}
      >
        Appointment Letter
      </Typography>
      <Box mt={3} mb={3}>
        <CustomMonthPicker date={date} setDate={setDate} />
      </Box>

      <Box sx={{ marginTop: "7px" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", width: "80%" }}>
            <UserSearchBar
              // defaultVal={defaultVal}
              onUserChange={onUserChange}
              // lazyFun={async (para) => await getUserApi({ ...para, allStatus: true })}
              // OptionComponent={({ option, ...rest }) => {
              //     return <ListItem {...rest}>{option.name}</ListItem>;
              // }}
              onChange={async (changedVal) => {
                setFields({
                  ...fields,
                  userId: changedVal ? changedVal._id : null,
                  name: changedVal.name,
                });
              }}
              titleKey={"name"}
              valueKey={"_id"}
              InputComponent={(params) => (
                <TextField
                  placeholder={"Select User"}
                  {...params}
                  margin="none"
                />
              )}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              sx={{ height: "7.5vh", width: "12vw" }}
              onClick={downloadList}
            >
              {loading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                <Typography>Generate PDF</Typography>
              )}
            </Button>
          </Box>
        </Box>
      </Box>

      {/* <Box sx={{ minHeight: "300px" }} mt={3}>
                <DataTable columns={columns} rows={state?.result} count={state?.length} filters={filters} setFilters={setFilters} loading={loading} />
            </Box> */}
    </Box>
  );
};

export default AppointmentMainUi;
