import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";

export const getUserAndSystemDataApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.userAndSystemFetchByToken,
    method: "get",
    headers: getHeaders(),
    params: data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const getUserSalaryStructureData = async (data) => {
  const callResponse = await axios({
    url: endpoints.userSalaryStructure,
    method: "get",
    headers: getHeaders(),
    params: data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const getBirthdayApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.userBirthdayBase,
    method: "get",
    headers: getHeaders(),
    params: data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const getUserApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.userBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const getUserByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.userById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const addUserApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.userBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const updateUserField = async (data) => {
  const callResponse = await axios({
    url: endpoints.userBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};
export const updateUserProfile = async (data) => {
  const callResponse = await axios({
    url: endpoints.userProfilePicture,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};
export const updateUserPass = async (data) => {
  const callResponse = await axios({
    url: endpoints.userPassword,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const updateUserState = async (data) => {
  const callResponse = await axios({
    url: endpoints.updateUserState,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const updateUserInOfficePresenece = async (data) => {
  const callResponse = await axios({
    url: endpoints.inOfficePresence,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const downloadUserProfilePdf = async (params) => {
  const callResponse = await axios({
    url: endpoints.userProfileReport,
    method: "get",
    headers: getHeaders(),
    params,
    responseType: "blob",
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const updateUserHiddenState = async (data) => {
  const callResponse = await axios({
    url: endpoints.HiddenBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const UserPolicyLogUpdate = async (data) => {
  const callResponse = await axios({
    url: endpoints.PolicyLogUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const getUserByParentId = async (params, signal) => {
  const callResponse = await axios({
    url: endpoints.fetchUserByParentId,
    method: "get",
    headers: getHeaders(),
    params,
    signal,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export default getUserAndSystemDataApi;
