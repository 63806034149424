import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Collapse,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Slide,
    Typography,
    TextField,
    CircularProgress, Autocomplete
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomInput from "../../components/inputs/CustomInput";
import { CenteredBox } from "../../components/layouts/common/boxes";
import SubmitButton from "../../components/button/SubmitButton";

const UpdateLeaveTypeUI = ({err, loading, UpdateLeaveType, setName, name,color,setColor, system_default,
    setSystem_default,
    identifier,
    setIdentifier,
    short_name,
    setShort_name,
    priority,
    setPriority,}) => {
    return (
        <>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          position: "relative",
        }}
        maxHeight="100%"
      >
        {loading && (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        )}
        {!loading && (
          <>
            <Box sx={{ marginTop: "10px", width: "100%" }}>
            <Typography color={"red"}>{err}</Typography>

              <Box>
                <Typography fontWeight={500} variant="h4">
                  {" "}
                  Name :{" "}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "7px" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <CustomInput
                      disabled={loading}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      placeholder="Write Name..."
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ marginTop: "10px", width: "100%" }}>
              <Box>
                <Typography fontWeight={500} variant="h4">
                  {" "}
                  Color :{" "}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "7px" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <CustomInput
                      disabled={loading}
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                      type="text"
                      placeholder="Write color..."
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ marginTop: "10px", width: "100%" }}>
              <Box>
                <Typography fontWeight={500} variant="h4">
                  Short Name :{" "}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "7px" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <CustomInput
                      disabled={loading}
                      value={short_name}
                      onChange={(e) => setShort_name(e.target.value)}
                      type="text"
                      placeholder="Write Short Name..."
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* <Box sx={{ marginTop: "10px", width: "100%" }}>
              <Box>
                <Typography fontWeight={500} variant="h4">
                  {" "}
                  System :{" "}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "7px" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <CustomInput
                      disabled={loading}
                      value={system_default}
                      onChange={(e) => setSystem_default(e.target.value)}
                      type="text"
                      placeholder="Write System..."
                    />
                  </Box>
                </Box>
              </Box>
            </Box> */}

<FormControl sx={{ marginTop: "10px"}}>
              <Typography component={Typography} variant="h4">
                System Default :
              </Typography>
              <RadioGroup
                value={system_default}
                onChange={(e) => {
                //   setFields({
                //     ...fields,
                //     used_from_advance: e.target.value ?? null,
                //     advance_payment_detail: null,
                //     advance_payment_id: null,
                //   });
                setSystem_default(e.target.value)
                }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>

            <Box sx={{ marginTop: "10px", width: "100%" }}>
              <Box>
                <Typography fontWeight={500} variant="h4">
                  {" "}
                  Identifier :{" "}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "7px" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <CustomInput
                      disabled={loading}
                      value={identifier}
                      onChange={(e) => setIdentifier(e.target.value)}
                      type="text"
                      placeholder="Write identifier..."
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{ marginTop: "10px", width: "100%" }}>
              <Box>
                <Typography fontWeight={500} variant="h4">
                  {" "}
                  Priority :{" "}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "7px" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <CustomInput
                      disabled={loading}
                      value={priority}
                      onChange={(e) => setPriority(e.target.value)}
                      type="text"
                      placeholder="Write Priority..."
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}

        <DialogActions>
          <Box sx={{ float: "right", marginTop: "7px" }}>
            <SubmitButton
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
              onClick={UpdateLeaveType}
              title="Update LeaveType"
            ></SubmitButton>
          </Box>
        </DialogActions>
      </Box>
    </>
    );
};

export default UpdateLeaveTypeUI;