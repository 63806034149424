import { Box, ButtonBase, Collapse, Divider, Grid, Paper, Skeleton, Typography, styled } from "@mui/material"
import { makeAmountString } from "../../utils/helper"
import { CenteredBox } from "../../components/layouts/common/boxes"
import { useState } from "react"

const ReimburesementCountOuter = styled(Box)(({ theme, color ,active}) => ({
    display: "flex",
    height: "100%",
    width: '100%',
    
    position: "relative",
    overflow: "hidden",

    backdropFilter: "opacity(0)",
    alignItems: "center",
    border:active?`1px solid ${color}`:"1px solid transparent",
    color:active?color: "black",
    borderRadius: theme.shape.borderRadius,
    '&::before': {
        content: '" "',
        display: 'block',
        position: "absolute",
        left: "0px",
        backgroundColor: color,
        width: "3px",
        borderTopRightRadius: "3px",
        borderBottomRightRadius: "3px",
        height: "50%",
        top: "25%"
    },

    '&::after': {
        content: '" "',
        display: 'block',
        position: "absolute",
        left: "0px",
        zIndex: -1,
        top: "0px",
        //
        backgroundColor: color,
        borderRadius: theme.shape.borderRadius,
        opacity: 0.1,
        width: "100%",
        height: "100%",

    }

}))

const ReimburesementTotalCountOuter = styled(Box)(({ theme, color }) => ({
    display: "flex",
    height: "100%",
    width: '100%',
    padding: theme.spacing(2),
    position: "relative",
    background: theme.palette.light.main,
    border: "2px dashed " + theme.palette.grey.main,
    borderRadius: theme.shape.borderRadius,
    alignItems: "center",


}))

const ReimburesementAdvanceTotalCountOuter = styled(Box)(({ theme, color }) => ({
    display: "flex",
    height: "100%",
    width: '100%',
    padding: theme.spacing(2),
    position: "relative",
    background: theme.palette.grey['300'],
    // border: "2px dashed " + theme.palette.grey.main,
    borderRadius: theme.shape.borderRadius,
    alignItems: "center",


}))

const AdvancePaymentCountComponent = ({ amount, used_amount }) => {
    return <Grid container  >
        <Grid item md={4} xs={4} sx={{ borderRight: "1px solid black" }}>
            <CenteredBox sx={{ flex: 1, flexDirection: "column", height: "100%" }}>
                <Typography variant="h3" align="center">
                    {amount} &nbsp;Rs.
                </Typography>
                <Typography variant="subtitle1" align="center" mb={1}>
                    Amount Claimed
                </Typography>
            </CenteredBox>

        </Grid>
        <Grid item md={4} xs={4} sx={{ borderRight: "1px solid black" }}>
            <CenteredBox sx={{ flex: 1, flexDirection: "column", height: "100%" }}>
                <Typography variant="h3" align="center">
                    {used_amount} &nbsp;Rs.
                </Typography>
                <Typography variant="subtitle1" align="center" mb={1}>
                    Amount Used
                </Typography>
            </CenteredBox>

        </Grid>


        <Grid item md={4} xs={4} >
            <CenteredBox sx={{ flex: 1, flexDirection: "column", height: "100%" }}>
                <Typography variant="h3" align="center">
                    {amount - used_amount} &nbsp;Rs.
                </Typography>
                <Typography variant="subtitle1" align="center" mb={1}>
                    Amount Remained
                </Typography>
            </CenteredBox>

        </Grid>



    </Grid>
}
const AdvancePaymentCount = ({ data }) => {
    const [open, setOpen] = useState()
    return <ReimburesementAdvanceTotalCountOuter onClick={() => setOpen(!open)} sx={{ flexDirection: "column" }} mt={3}>
        <Typography variant="h5" fontWeight="600" mb={4}>
            Advance Payment
        </Typography>


        <AdvancePaymentCountComponent {...data} />

        <Collapse in={open && data.amount > 0} sx={{ width: "100%" }} >
            <Box variant="OUTLINED" sx={{ width: "100%", mt: 5, p: 3 }} >
                <Typography variant="h5" align="center" fontWeight="600" mb={4}>
                    Advance Payment Details
                </Typography>
                {
                    data?.data?.map((item) =>

                        <Box variant="outlined" component={Paper} key={item._id} mb={3}>
                            <Box p={2} >
                                <Typography textTransform="capitalize">{item.purpose}</Typography>
                            </Box>
                            <Divider />
                            <Box p={2} >
                                <AdvancePaymentCountComponent {...item} />
                            </Box>
                        </Box>
                    )
                }

            </Box>

        </Collapse>
    </ReimburesementAdvanceTotalCountOuter>
}

const ReimburesementCount = ({ title, subTitle, count, color, filters, setFilters, type, dashboard }) => {
    const onClick = () => {
        if (filters)
            setFilters({ ...filters, type })
    }
    return <ReimburesementCountOuter onClick={dashboard? '#' : onClick} component={filters ? ButtonBase : undefined} color={color} p={3} active={filters && filters.type == type} >
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "flex-start", alignItems: "flex-start" }} p={1} pr={0} pl={2}>
            <Typography variant='h4' fontWeight="600">
                {title}
            </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end" }} p={1} pr={2}>
            <Typography variant='h3' fontWeight="600" lineHeight="100%">
                {makeAmountString(count)}
            </Typography>
            <Typography variant='h6' color="grey" >
                &nbsp;Rs.
            </Typography>
        </Box>
    </ReimburesementCountOuter>
}

const ReimburesmentCounts = ({ data, loading, filters, setFilters, dashboard }) => {
    const color = ["#FF9500", "#FF0000", "#27D8BA", "#001DFF"]
    const countCounts = (item) => {
        return item.approved + item.pending + item.rejected + item.paid
    }
    return <Box sx={{ width: "100%" }}>
        <Grid container columnSpacing={2} rowGap={3} >
            <Grid item md={7} xs={12}>
                <Grid container spacing={1} sx={{ height: "100%" }}>
                    {
                        loading && [1, 2, 3, 4].map((key) => <Grid item key={key} md={3} xs={6}> <Skeleton width="100%" height="150px"></Skeleton></Grid>)
                    }
                    {
                        !loading && data.data && data.data.length > 0 && data.data.map((item, index) => {

                            return <Grid item key={item.type} md={6} xs={6}>
                                <ReimburesementCount filters={filters} setFilters={setFilters} type={item.type} title={item.type && item.type.toUpperCase()} count={countCounts(item)} color={color[index]} dashboard={dashboard}/>


                            </Grid>
                        })
                    }
                </Grid>
            </Grid>
            <Grid item md={5} xs={12}>
                {loading && <Skeleton width="100%" height="150px"></Skeleton>}
                {
                    !loading && data.total && <ReimburesementTotalCountOuter>
                        <Grid container  >
                            <Grid item md={3} xs={3} sx={{ borderRight: "1px solid black" }}>
                                <CenteredBox sx={{ flex: 1, flexDirection: "column", height: "100%" }}>
                                    <Typography variant="h3" align="center">
                                        {countCounts(data.total)} Rs.
                                    </Typography>
                                    <Typography variant="subtitle1" align="center" mb={1}>
                                        Total Amount
                                    </Typography>
                                </CenteredBox>

                            </Grid>
                            <Grid item md={3} xs={3} sx={{ borderRight: "1px solid black" }}>
                                <CenteredBox sx={{ flex: 1, flexDirection: "column", height: "100%" }}>
                                    <Typography variant="h3" align="center">
                                        {data.totalkm} Km.
                                    </Typography>
                                    <Typography variant="subtitle1" align="center" mb={1}>
                                        Total Km
                                    </Typography>
                                </CenteredBox>

                            </Grid>
                            <Grid item md={6} xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item md={12} xs={12}>

                                        <Typography color="success.main" variant="h3" align="center">
                                            {data.total && data.total.paid}  &nbsp;Rs.
                                        </Typography>
                                        <Typography color="success.main" variant="subtitle1" align="center" mb={1}>
                                            Paid Amount
                                        </Typography>

                                    </Grid>
                                    <Grid item md={6} xs={6}>

                                        <Typography color="success.main" variant="h3" align="center">
                                            {data.total && data.total.approved}  &nbsp;Rs.
                                        </Typography>
                                        <Typography color="success.main" variant="subtitle1" align="center" mb={1}>
                                            Approved Amount
                                        </Typography>

                                    </Grid>
                                    <Grid item md={6} xs={6}>
                                        <Typography color="error.main" variant="h3" align="center">
                                            {data.total && data.total.rejected}  &nbsp;Rs.
                                        </Typography>
                                        <Typography color="error.main" variant="subtitle1" align="center" mb={1}>
                                            Rejected Amount
                                        </Typography>

                                    </Grid>
                                </Grid>
                                {/* <Typography variant="h3" align="center">
                                    {countCounts(data.total)} &nbsp;Rs.
                                </Typography>
                                <Typography variant="subtitle1" align="center" mb={1}>
                                    Total Amount
                                </Typography> */}

                            </Grid>


                        </Grid>

                    </ReimburesementTotalCountOuter>
                }

            </Grid>
        </Grid>

        {
            !loading && data.advancePayment && <>

                < AdvancePaymentCount data={data.advancePayment} />

            </>
        }

    </Box>


}
export default ReimburesmentCounts