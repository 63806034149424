import React, { useCallback, useEffect, useMemo, useState } from "react";
import AppointmentMainUi from "./AppointmentMainUi";
import { useDispatch, useSelector } from "react-redux";
import FileDownloadComponent from "../../components/FileDownloadComponent";
import { callApiAction } from "../../store/actions/commonAction";
import { Delete, DoneAll } from "@mui/icons-material";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { IconButton, Typography } from "@mui/material";
import moment from "moment";
import {
  downloadAppointmentApi,
  fetchAppointmentApi,
} from "../../apis/appointment.api";
import { useParams } from "react-router-dom";
import fileDownload from "js-file-download";

const AppointmentMainController = ({ date }) => {
  const user = useSelector((state) => state.user);
  console.log("USER", user);
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state);
  const [selectedUser, setSelectedUser] = useState(null);
  const params = useParams();

  const onUserChange = useCallback((user) => {
    console.log("object usersssss", user);
    setSelectedUser(user);
  }, []);

  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    userId: "",
    err: "",
    first_name: "",
    last_name: "",
  });

  console.log("Fields", fields);

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["userId.employee_id"],
    sort: "createdAt",
    userId: "",
    sortDirection: -1,
  });

  const downloadList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await downloadAppointmentApi({ userId: fields.userId }),
        (response) => {
          setLoading(false);
          console.log(response, "response in pdf ");
          if (
            response.status === 0 ||
            response.code === 400 ||
            response.message === "no Final Settlement Sheet found"
          ) {
            dispatch(callSnackBar("NoT PDF found", SNACK_BAR_VARIETNS.error));
          } else {
            setLoading(false);
            fileDownload(
              response,
              //   fields.userId?.first_name +
              //     "" +
              //     fields.userId?.last_name +
              "Appointment.pdf"
            );
            dispatch(
              callSnackBar(
                "Pdf Downloaded Successfully",
                SNACK_BAR_VARIETNS.suceess
              )
            );
            dispatch(closeModal());
          }
        },
        (err) => {
          setLoading(false);
          dispatch(
            callSnackBar("Can't download Pdf", SNACK_BAR_VARIETNS.error)
          );
        },
        true
      )
    );
  };

  return (
    <>
      <AppointmentMainUi
        date={date}
        filters={filters}
        setFilters={setFilters}
        setState={setState}
        fields={fields}
        setFields={setFields}
        //   callBack={downloadList}
        params={params}
        loading={loading}
        state={state}
        downloadList={downloadList}
        onUserChange={onUserChange}
      />
    </>
  );
};

export default AppointmentMainController;
