import {
  Box,
  ButtonBase,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { CALENDAR_ITEM_TYPES, LEAVE_COLORS } from "../../../../utils/constants";
import { center } from "../../../../assets/css/theme/common";
import { TASK_STATUS } from "../../../../utils/task.constants";
import { setStringView } from "../../../../utils/helper";
import moment from "moment";
import {
  Check,
  CheckCircle,
  CheckCircleOutline,
  Warning,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { CenteredBox } from "../../../../components/layouts/common/boxes";
import { EmployeeTaskUserContext } from "../../EmployeeMain";
import { useContext } from "react";
import { LEAVE_STATUS } from "../../../../utils/leave.constants";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../store/actions/modalAction";
import DailyUpdateReportController from "../../DailyUpdate/DailyUpdateReportController";

const TaskItem = styled(ButtonBase)(({ color, background, type }) => ({
  position: "relative",
  zIndex: 1,
  width: "100%",
  padding: "4px",
  backgroundColor: background ?? color,
  "::after": {
    content: "' '",
    position: "absolute",
    top: "0px",
    left: "0px",
    height: "100%",
    width: "100%",
    zIndex: -1,
  },
  "*": {
    color:
      type === CALENDAR_ITEM_TYPES.daily_updates
        ? "#fff"
        : background
        ? color
        : "#d68332 !important",
  },
}));
const LeaveViewBox = styled(ButtonBase)(({ theme, color, background }) => ({
  position: "relative",
  zIndex: 1,
  width: "100%",

  "::after": {
    content: "' '",
    position: "absolute",
    top: "0px",
    left: "0px",
    height: "100%",
    width: "100%",
    background: background ?? color,
    // opacity: 0.2,
    zIndex: 0,
  },
  "*": {
    color: color + "!important",
  },
}));

const fetchBgColor = (status) => {
  let bgColor = LEAVE_COLORS.HOLIDAY;
  let color = "white";
  if (status == LEAVE_STATUS.PENDING) {
    bgColor = "gray";
  }
  if (status == LEAVE_STATUS.APPROVED) {
    bgColor = "#008000";
  }
  if (status == LEAVE_STATUS.REJECTED) {
    bgColor = "red";
  }
  return {
    bgColor,
    color,
  };
};

const SmallCalendarButton = styled(ButtonBase)(({ theme }) => ({
  height: "35px",
  width: "35px",
  borderRadius: "100%",
  lineHeight: "100%",
  backgroundColor: "#f2f2f2",
}));

const findColor = (status, type) => {
  if (type === CALENDAR_ITEM_TYPES.pms_task) {
    return "#fff2e7";
  }
  if (status === TASK_STATUS.COMPLETED) {
    return "rgba(55, 221, 151, 1)";
  }
  if (status === TASK_STATUS.REJECTED) {
    return "rgba(255, 24, 24, 1)";
  }
  if (status === TASK_STATUS.PENDING) {
    return "rgba(230, 111, 25, 1)";
  }
  if (type === CALENDAR_ITEM_TYPES.daily_updates) {
    return "green"; // Use green for daily updates
  }
  return "grey";
};
const MobileViewDesktop = ({
  data,
  date,
  weekDay,
  weeklyOf,
  isMobile,
  onClick,
  isHoliday,
  dailyUpdates,
  handleDailyUpdateClick,
  selectedUser,
  ...props
}) => {
  const theme = useTheme();

  return (
    <CenteredBox sx={{ width: "100%", flexDirection: "column" }} p={1}>
      <SmallCalendarButton
        sx={{
          background: isHoliday ? "#D58C37" : "#f2f2f2",
          color: isHoliday ? "#fff" : "unset",
          flexDirection: "column",
        }}
        onClick={() => handleDailyUpdateClick(date)}
      >
        {selectedUser && (
          <Box
            sx={{
              height: "6px",
              width: "6px",
              background:
                dailyUpdates?.length > 0
                  ? theme.palette.success.main
                  : theme.palette.grey[400],
              borderRadius: "100%",
              position: "absolute",
              top: "2px",
            }}
          />
        )}

        {date}
      </SmallCalendarButton>

      <CenteredBox>
        {data
          .filter(
            (item) =>
              item.type === CALENDAR_ITEM_TYPES.task ||
              item.type === CALENDAR_ITEM_TYPES.pms_task
          )
          .map((item) => (
            <Box
              onClick={() => handleDailyUpdateClick(date)}
              key={item.title}
              sx={{
                height: "6px",
                width: "6px",
                background: findColor(item.status, item.type),
                borderRadius: "100%",
              }}
            />
          ))}
      </CenteredBox>
    </CenteredBox>
  );
};

const CalendarInnerBox = ({
  data,
  weeklyOf,
  weekDay,
  date,
  isMobile,
  calendarTimeObj,
  ...props
}) => {
  const isCompanyHoliday = data.find(
    (item) => item.type === CALENDAR_ITEM_TYPES.holiday
  );
  const selectedUser = useContext(EmployeeTaskUserContext);
  const dailyUpdates = data?.filter(
    (item) => item.type === CALENDAR_ITEM_TYPES.daily_updates
  );
  const dispatch = useDispatch();
  const isWeeklyOf = weeklyOf.includes(weekDay);
  const leavesToDisplay = selectedUser
    ? data?.filter(
        (item) =>
          item.type === CALENDAR_ITEM_TYPES.leave &&
          item.applied_by?._id === selectedUser._id
      )
    : data?.filter((item) => item.type === CALENDAR_ITEM_TYPES.leave);

  const handleDailyUpdateClick = (day) => {
    const year = calendarTimeObj.year();
    const month = calendarTimeObj.month() + 1;
    const fullDate = moment(`${year}-${month}-${day}`, "YYYY-MM-DD").format(
      "YYYY-MM-DD"
    );

    console.log("Original Date:", day);
    console.log("Full Date:", fullDate);

    dispatch(
      openModal({
        component: (
          <DailyUpdateReportController
            date={fullDate}
            userId={selectedUser?._id}
          />
        ),
        title: "Daily Update",
        size: "sm",
      })
    );
  };

  if (isMobile) {
    return (
      <MobileViewDesktop
        data={data}
        date={date}
        weekDay={weekDay}
        weeklyOf={weeklyOf}
        dailyUpdates={dailyUpdates}
        handleDailyUpdateClick={handleDailyUpdateClick}
        selectedUser={selectedUser}
        {...props}
        isHoliday={isWeeklyOf || isCompanyHoliday}
      />
    );
  }

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        flexDirection: "column",
        justifyContent: "flex-end",
        display: "flex",
        flex: 1,
      }}
      pt={5}
    >
      {selectedUser && dailyUpdates?.length > 0 ? (
        <ButtonBase
          // onClick={() => handleDailyUpdateClick(date)}
          sx={{ position: "absolute", top: "5px", left: "5px" }}
        >
          <Tooltip title="This icon denotes they have added there daily update">
            <CheckCircle color="success" fontSize="inherit" />
          </Tooltip>
        </ButtonBase>
      ) : (
        selectedUser && (
          <Box sx={{ position: "absolute", top: "5px", left: "5px" }}>
            <Tooltip title="This icon denotes they have not added there daily update">
              <CheckCircleOutline color="grey" fontSize="inherit" />
            </Tooltip>
          </Box>
        )
      )}
      {data &&
        data.map((item, id) => {
          if (
            item.type === CALENDAR_ITEM_TYPES.task ||
            item.type === CALENDAR_ITEM_TYPES.pms_task ||
            item.type === CALENDAR_ITEM_TYPES.daily_updates
          ) {
            return (
              <TaskItem
                color={findColor(item.status, item.type)}
                onClick={item.onClick || (() => handleDailyUpdateClick(date))}
                key={id}
                type={item.type}
                sx={{ zIndex: 1, height: "3vh" }}
              >
                <Typography variant="caption">{item.title}</Typography>
              </TaskItem>
            );
          }
          return null;
        })}

      {/* {leavesToDisplay && leavesToDisplay.length > 0 && leavesToDisplay.map((item, id) => {
                const { bgColor, color } = fetchBgColor(item.status);
                return (
                    <LeaveViewBox onClick={item.onClick} key={id} color={color} background={bgColor}>
                        <Typography align="center" color="dark.main" sx={{ zIndex: 1 }} variant="caption">
                            {item.applied_by?.first_name?.slice(0, 10) ?? "NA"}
                            {item.applied_by?.first_name?.length > 10 && ".."}
                        </Typography>
                    </LeaveViewBox>
                );
            })} */}

      {(isWeeklyOf || isCompanyHoliday) && (
        <Box
          sx={{
            zIndex: 1,
            background: "#D58C37",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Typography color="light.main" variant="caption">
            {isCompanyHoliday ? isCompanyHoliday.title : "WO"}
          </Typography>
        </Box>
      )}

      {(isWeeklyOf || isCompanyHoliday) && (
        <Box
          sx={{
            zIndex: 0,
            top: "0px",
            width: "100%",
            position: "absolute",
            height: "100%",
            background: "#f1f1f1",
          }}
        />
      )}
    </Box>
  );
};

export default CalendarInnerBox;
